import React from "react"
import Layout from "../components/Layout"
import Services from "../components/Services"

const seoProps = {
  title: "Services",
  description:
    "Stevenson Roofing have over 30 years experience in a range of roofing and building services",
  image: "/img/sawing1.jpg",
  pathname: "/services",
}

const ServicesPage = () => (
  <Layout seoProps={seoProps}>
    <Services />
  </Layout>
)

export default ServicesPage
