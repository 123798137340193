import React, { Component } from "react"
import { Link } from "gatsby"
import { routes } from "../routes/routes"
import { layoutImg } from "../routes/imgRoutes"

class Services extends Component {
  render() {
    return (
      <div id="services" className="section-spacing">
        <div className="container borders">
          <div className="columns">
            <div className="column is-12">
              <div className="sectionHeading">
                <h4 className="is-size-3 centerText">Services</h4>
                <hr />
              </div>
              <div className="content third-content">
                <div className="columns">
                  <div className="column is-7">
                    <img
                      src={`../${layoutImg.layout1.url}`}
                      alt={layoutImg.layout1.alt}
                    />
                  </div>
                  <div className="column is-5">
                    <div className="right-feature-text">
                      <h4>Roofing Services</h4>
                      <p>
                        Here is a range of some of the many services we offer.
                        If there is a job you need doing and it isn't listed
                        below, feel free to still get in touch.
                      </p>
                      <div className="feature-list">
                        <ul>
                          <li>Full roof installs, tiles or slate</li>
                          <li>Lift and replace</li>
                          <li>Soffits & facias</li>
                          <li>Firestone flat roofing</li>
                          <li>Guttering & downpiping</li>
                          <li>Single ply flat roofing</li>
                          <li>Lead work</li>
                          <li>And more...</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="centerText">
                <Link to={routes.contact} className="button is-link is-medium">
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Services
